import { useEffect, useState } from 'react';
import './App.css';
import {NiceLink} from './NiceLink';
import Console from './Console';
import i18n from './i18n';

// rozřířím window
declare global {
  interface Window {
    handleLanguageChange: (lng: string) => void;
  }
}


export default function App() {

  const [mousedLink, setMousedLink] = useState<string>();

  useEffect(()=> {
    window.handleLanguageChange = handleLanguageChange;
  },[])

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    setMousedLink('changeLang');
    console.log("vyvolána změna jakyku");
  }
  
  const handleHover = (source: string) => {  
    setMousedLink(source);
    console.log("myš je nad", source);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Hardtrace
        </h1>
      </header>

      <div className='centerLinks'>
        <NiceLink targetURL='https://store.steampowered.com/app/3229710/Hardtrace/' description='Steam' onHover={handleHover}/>
        <NiceLink targetURL='https://x.com/devbobcz' description='X' onHover={handleHover}/>
        <NiceLink targetURL='https://www.twitch.tv/devbobcz' description='Twitch' onHover={handleHover}/>
        <NiceLink targetURL='https://www.linkedin.com/in/devbobcz' description='LinkedIn' onHover={handleHover}/>
        <NiceLink targetURL='https://devbobcz.itch.io/hardtrace' description='Itch' onHover={handleHover}/>
        <NiceLink targetURL='https://discord.gg/dYqKJZMfqJ' description='Discord' onHover={handleHover}/>
      </div>
      
      <div className='consoleContainer'>
        <div className='consoleOutput'>
          <Console mousedLink={mousedLink}/>
        </div>
      </div>
      

    </div>
  );
}
