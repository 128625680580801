import React from 'react';
import './App.css';

interface NiceLinkProps {
    targetURL: string;
    description: string;
    onHover: (source: string) => void;
}

export function NiceLink(props: NiceLinkProps) {
    return <a href={props.targetURL} target='_blank' className='niceLink' onMouseEnter={() => props.onHover(props.description)}>{props.description}</a>;
}
