import { useEffect, useRef, useState } from 'react';
import './App.css';
import funnyTextJSON from './funnytexts.json';
import { useTranslation} from 'react-i18next';

interface FunnyText{
    id: number;
    key: string;
    text: string;
    purpose: string;
}


interface ConsoleProps {
    mousedLink?: string
}

export default function Console(props: ConsoleProps) {
    
    const [funnyTexts, setFunnyTexts] = useState<FunnyText[]>([]);
    const [activeText, setActiveText] = useState<FunnyText>();
    const [shinyCursor, setShinyCursor] = useState<number>(0);
    const [lastMoused, setLastMoused] = useState<string>('');
    const [activeLanguage, setActiveLanguage] = useState<string>('');
    const [localeDateTimeString, setLocaleDateTimeString] = useState('');

    const [renew, setRenew] = useState<boolean>();
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const { t, i18n } = useTranslation();
    
    /**
     * Vrací náhodný text 
     */
    function getRandomFunnyText(array: FunnyText[]): FunnyText | undefined {
        
        var subset = array.filter(text=> text.purpose === '');
        const randomIndex = Math.floor(Math.random() * subset.length);
        return subset[randomIndex];
    }

    /**
     * generické jsme nahradil za negenerické, zde potřebuju hledat v konkrétním typu pole
     * @param array 
     * @param purpose 
     * @returns 
     */
    function getRandomFunnyTextWithPurpose(array: FunnyText[], purpose: string): FunnyText | undefined {    
        // zjistím texty k mému tlačítku
        var subset = array.filter(text=> text.purpose === purpose);
        const randomIndex = Math.floor(Math.random() * subset.length);
        return subset[randomIndex];
    }

    /**
     * Výměna textací
     */
    useEffect(() => {
        
        console.log(`data pro vyhodnocení, ${props.mousedLink}, ${lastMoused}, ${renew}, ${activeText}`);

        if(renew === true || (props.mousedLink && lastMoused !== props.mousedLink) || activeText?.text === '' || activeLanguage !== i18n.language)
        {
            let randText: FunnyText | undefined = getRandomFunnyText(funnyTexts);       
            if(props.mousedLink && (lastMoused !== props.mousedLink || activeLanguage !== i18n.language))
            {
    
                console.debug('Změna moused');
                // změna textu za účelový
                randText = getRandomFunnyTextWithPurpose(funnyTexts, props.mousedLink);   
            }
    
            if(props.mousedLink)
            {
                setLastMoused(props.mousedLink);
            }
    
            if(randText!==undefined && randText!==null && (randText.key !== activeText?.key || activeLanguage !== i18n.language))
            {
                // překlad
                console.log(`Původní text: ${randText.text}`);
                randText.text = t(randText.key);
                console.log(`Překlad: ${randText.text}`);
                randText.text = randText.text.replaceAll('%timeCZ', localeDateTimeString);
                setActiveText(randText);
                setShinyCursor(0);
            }

            setRenew(false);

            // update aktivního jazyku abych mohl zjistit jeho změnu
            if(activeLanguage !== i18n.language)
            {
                setActiveLanguage(i18n.language);
            }
        }
        
    },[activeText,funnyTexts, renew, props.mousedLink, lastMoused, localeDateTimeString, t, i18n.language, activeLanguage]);


    /**
     * Počáteční načtení textů
     */
    useEffect(() => {
        // Načti JSON data
        setFunnyTexts(funnyTextJSON);
        setActiveText({id:-1, key:"", text: "", purpose: ""});

        // update aktuálního času
        const updateLocaleString = () => {
            var cas = new Date();
            var localeString = `${cas.toLocaleDateString()} - ${cas.toLocaleTimeString()}`;
            setLocaleDateTimeString(localeString);
        };

        updateLocaleString();
        const interval = setInterval(updateLocaleString, 1000);

        return () => clearInterval(interval);
    }, []);


    /**
     * Postupné vykreslování textů
     */
    useEffect(() => {

        if(intervalRef.current)
        {
            clearInterval(intervalRef.current);
        }
        
        // postupné vykreslování textu
        intervalRef.current = setInterval(()=>{
            let activeTextLen = activeText?.text.length ? activeText?.text.length:0;
            setShinyCursor(shinyCursor+1);
            if(activeTextLen + 200 < (shinyCursor+1))
            {
                setRenew(true);
            }
        }, 50);

    }, [shinyCursor, activeText]);

    
    return <p key={activeText?.id}>{activeText?.text.substring(0,shinyCursor-1)}<b className='shinySymbol'>{activeText?.text.substring(shinyCursor-1,shinyCursor)}</b><b className='cursorSymbol'>|</b></p>;
}
