import translation_en from "./translations/en/common.json";
import translation_cs from "./translations/cs/common.json";

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
      lookupCookie: 'i18next',
    },
    supportedLngs: ['cs', 'en'],
    resources: {
      en: {
        common: translation_en
      },
      cs: {
        common: translation_cs
      },
    },
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;